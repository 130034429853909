import { ref, computed } from "vue";
import { CURRENCIES } from "@mono/constants/lib/currency";

const currencyCodes = CURRENCIES.map((c) => c.code);
export type CurrencyCode = (typeof currencyCodes)[number];

// state outside the composable function represent shared global app state
const currencyCode = ref<CurrencyCode>("sar");
const currentCurrency = computed(() => {
  return CURRENCIES.find((c) => c.code === currencyCode.value)!;
});

// methods
const setCurrency = (code?: CurrencyCode | string) => {
  currencyCode.value = currencyCodes.includes(code as CurrencyCode)
    ? (code as CurrencyCode)
    : "sar";
};
const clearCurrency = () => {
  currencyCode.value = "sar";
};

export const useCurrency = ({
  extraDecimals,
  showZero,
}: { extraDecimals?: number; showZero?: boolean } = {}) => {
  const offset = currentCurrency.value.offset;
  const offsetWithExtraDecimals = offset + (extraDecimals ?? 0);

  // offset represented as a multiplication factor
  // Ex: 100 for SAR because offset is 2
  const currencyExponent = computed<number>(() => {
    return 10 ** offset;
  });

  const currencyPlaceholder = computed<string>(() => {
    return `0.${"0".repeat(offset)}`;
  });

  const currencyRegex = computed(() => {
    const zeroLeadingRegex = `0(\\.[0-9]{0,${offsetWithExtraDecimals}})?`;
    const nonZeroLeadingRegex = `[1-9][0-9]*(\\.[0-9]{0,${offsetWithExtraDecimals}})?`;
    return new RegExp(`^(${zeroLeadingRegex}|${nonZeroLeadingRegex})$`);
  });

  const formatCurrency = (value: number) => {
    if (value === 0 && !showZero) {
      // if value is 0 and showZero is false, return empty string
      // to prevent displaying zero's in the UI
      return "";
    }

    if (value === 0 && showZero) {
      // if value is 0 and showZero is true, return the currency placeholder
      // to display zero's in the UI as placeholders
      return currencyPlaceholder.value;
    }

    const amount = value / currencyExponent.value;
    const formattedAmount = (amount + 0.00001).toFixed(offsetWithExtraDecimals);

    // handle trailing zeros if from extra decimals only
    if (extraDecimals === 0 || extraDecimals === undefined) {
      return formattedAmount;
    } else if (formattedAmount.endsWith("0".repeat(extraDecimals))) {
      return formattedAmount.slice(0, -extraDecimals);
    } else {
      return formattedAmount;
    }
  };
  const parseCurrency = (value: string) => {
    // if regex test fails, return an invalid value that will cause zod validation to fail
    if (value !== "" && !currencyRegex.value.test(value)) {
      return NaN;
    }

    const parsed = parseFloat(value);
    if (isNaN(parsed)) return undefined;

    // multiply by the currency exponent to get the actual value
    // and round to the offset
    // Ex: 100 for SAR because offset is 2
    // needed to prevent floating point errors
    return parseFloat(
      (parsed * currencyExponent.value).toFixed(offsetWithExtraDecimals)
    );
  };

  // Utility function to round with extra decimals
  const roundWithExtraDecimals = (
    amount: number,
    extraDecimals: number = 1
  ) => {
    const factor = Math.pow(10, extraDecimals);
    return Math.round(amount * factor) / factor;
  };

  return {
    currentCurrency,
    currencyPlaceholder,
    currencyExponent,
    setCurrency,
    clearCurrency,
    currencyRegex,
    formatCurrency,
    parseCurrency,
    roundWithExtraDecimals,
  };
};
